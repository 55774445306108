<template>
	<div class="aboutUs">
		<h1>公司简介</h1>
		<p class="brief" v-for="(item,index) in data.brief" :key="index">{{item}} </p>
		<h2>企业使命</h2>
        <p class="msg">
           {{data.task}}
        </p>
        <!-- <h2>创始人简介 </h2>
        <p class="msg-brief">
           {{data.companyBrief}}
        </p> -->
        <h2>大事记</h2>
		<p class="msg">
			<ul v-for="(item, idx) in data.notes" :key="idx">
				<li> {{item}}</li>
			</ul>
		</p>
          <h2>企业荣誉</h2>
		<p class="msg">
			<ul v-for="(item, idx) in data.glory" :key="idx">
				<li> {{item}}</li>
			</ul>
		</p>
        <h2>融资情况 </h2>
        <p class="msg">
          <ul> 
              <li v-for="(item,index) in data.money" :key="index">{{item}}</li>
         </ul>
        </p>
         <h2 v-if="data.invest&&data.invest.length>0">投资方介绍</h2>
        <p class="msg">
            <ul>
                <li v-for="(item,index) in data.invest" :key="index">{{item.name}}</li>
            </ul>

        </p>
	</div>
</template>
<script>
export default {
	name: 'aboutUs',
	data () {
		return {
            data:{}
		}
	},
    created(){
        this.getData()
    },
    methods:{
        getData(){
            // console.log(data)
            // this.data=data
        this.axios.get('https://sc2.hexiaoxiang.com/web_static/new_hx_web/aboutUs-20210827.json').then(res=>{
           this.data=res.data
           console.log(this.data)
         })
        }
    }
}
</script>
<style lang="scss" scoped>
.aboutUs{
    text-align: left;
    
    h1{
        font-size: 50px;
        color:#444144;
        font-weight: bold;
    }
    .brief{
        font-size: 18px;
        color: #444144;
        line-height: 34px;
        text-indent:2em;
        margin: 28px 0 30px;
    }
    h2{
        color: #FF7041;
        font-size: 32px;
        font-weight: bold;
    }
    .msg{
        font-size: 18px;
        color: #444144;
        line-height: 30px;
        margin: 17px 0 38px;
    }

    .msg li span{
        font-weight: bold;
        margin-right: 10px;
    }
    .msg p{
           text-indent: 2em;
    }
    .msg-brief{
        font-size: 18px;
        color: #444144;
        line-height: 34px;
        text-indent:2em;
        margin: 28px 0 30px;
    }
}
</style>
